<template>
  <div>
    <CommonListLabel
      :label="'Seneste ugebrev'"
      :link="'/ugebreve'"
      :translate-label="false"
    />
    <CommonDivider
      :dark="true"
      :class="placement === 'side' ? '' : 'mt-0 md:mt-3 mb-3 md:mb-5'"
    />
    <div v-if="magazine" class="mb-3">
      <NuxtLink :to="userStore.isLoggedIn ? '' : 'ugebreve'">
        <CommonImageLoader
          :width="516"
          :height="697"
          class="overflow-hidden rounded-md"
          :src="magazine.ImageUrl"
        />
      </NuxtLink>
      <NuxtLink
        v-if="userStore.isLoggedIn"
        :to="link"
        v-bind="
          downloadLinkActive
            ? {
                external: true,
                download: magazine.Name,
                target: '_blank',
              }
            : {}
        "
      >
        <CommonButton
          bg="bg-black"
          text="text-white"
          border="border-none"
          class="mt-6 group"
        >
          {{
            downloadLinkActive
              ? 'Download ugebrev'
              : 'Køb abonnement og få adgang'
          }}
          <DownloadIcon
            class="text-white fill-current w-4 h-4 ml-1 inline transition-colors duration-100 group-hover:text-black"
          />
        </CommonButton>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import DownloadIcon from '~/assets/icons/icon-action-download.svg?component'
const nuxtApp = useNuxtApp()

const userStore = useUserStore()

withDefaults(
  defineProps<{
    placement?: string
  }>(),
  {
    placement: 'side',
  }
)
const { data: magazine } = await useAsyncData(
  async () => (await nuxtApp.$api.content.magazine(1))[0]
)

const downloadLinkActive = computed(() => {
  return userStore.hasSubscription(1)
})
const link = computed(() => {
  if (!magazine.value) {
    return
  }

  if (downloadLinkActive.value) {
    if (
      userStore.hasLoginType('IP', 'Wayf') &&
      userStore.user?.legacyAutoToken // allow none jwt migrated users to user legacy token
    ) {
      return `${new URL(magazine.value.Link).pathname}?token=${encodeURI(
        userStore.user?.legacyAutoToken ?? ''
      )}`
    }
    return new URL(magazine.value.Link).pathname
  } else {
    return { name: 'subscription' }
  }
})
</script>
